.navbar {
  background: #ffc0cb ;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}
  
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-height: 80px;
  max-width: 1700px;
}

@import url(//db.onlinewebfonts.com/c/36fda95b699fea73e18a8f2d1ad6a6c0?family=Amasis+MT);

@font-face { 
  font-family: "Amasis MT"; 
  src: url("//db.onlinewebfonts.com/t/36fda95b699fea73e18a8f2d1ad6a6c0.eot"); 
  src: url("//db.onlinewebfonts.com/t/36fda95b699fea73e18a8f2d1ad6a6c0.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/36fda95b699fea73e18a8f2d1ad6a6c0.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/36fda95b699fea73e18a8f2d1ad6a6c0.woff") format("woff"), url("//db.onlinewebfonts.com/t/36fda95b699fea73e18a8f2d1ad6a6c0.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/36fda95b699fea73e18a8f2d1ad6a6c0.svg#Amasis MT") format("svg"); 
}


.navbar-logo h1 {
  color: #ff1493;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  font-family: 'Amasis MT', sans-serif;
  font-weight: bolder;
}

.logo {
  display: none;
  max-height: 80px;
  margin-right: 10px; 
}
  
.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 1px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
}
  
.nav-item {
  height: 80px;
}
  
.nav-links {
  color: #ff1493;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}
  
.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}
  
.fa-bars {
  color: #ff1493;
}

.cart {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.fas {
  color: #ff1493;
  font-size: 2rem;
}

.cart:hover {
  transform: scale(1.2);
}

.cart span:nth-child(2){
  padding: 0 3px;
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: .9rem;
  border-radius: 5px;
  position: relative;
  top: -15px;
}
  
/* .nav-links-mobile {
  display: none;
} */
  
.menu-icon {
  display: none;
}

/* @media (width > 960px) {
  .nav-menu {
    grid-template-columns: repeat(4, auto);
  }
} */
  
@media screen and (max-width: 1250px) {
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }

  .navbar-logo h1 {
    margin-right: auto;
  }
  
  .menu-icon {
    display: block;
    margin-left: 29rem;
    margin-right: 2rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }

  .menu-icon:hover {
    transform: scale(1.2);
  }
  
  .cart {
    margin-left: 0;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #ffc0cb;
    justify-content: flex-start;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y: scroll;
  }

  .nav-links {
    text-align: center;
    top: 0;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #f78fa7;
    color: #fff;
    border-radius: 0;
  }

  .fa-times {
    color: #ff1493;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  } 
}

@media screen and (max-width: 1049px) {
  .navbar-container {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
  .menu-icon {
    display: block; 
    margin-left: 0;
    margin-right: 0;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    order: 0;
  }

  .menu-icon:hover{
    transform: scale(1.2);
  }

  .navbar-logo.logo {
    display: block;
    width: 90px;
    margin-right: 0;
    margin-left: 22px;
    order: 1;
  }
  .cart {
    order: 2;
  }

  .navbar-logo h1 {
    display: none;
  }

  .navbar-logo.logo img {
    display: block;
    width:90px;
    object-fit: contain;
  }
}

