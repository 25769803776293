.about-hero-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
}

.about-hero-intro {
    margin: 3em
}

.laptop {
    display: grid;
    grid-template-columns: 1fr;
}

.phone {
    display:none;
}

.laptop-images {
    width: 100%;
}

.about-hero-intro h1 {
    color: #ff1493;
    text-align: center;
}

@media screen and (max-width: 550px) {
    .laptop {
        display: none;
    }
    
    .phone {
        display: grid;
        grid-template-columns: 1fr;
    }

    .phone-images {
        width:100%;
    }
}