.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.form-container-heading {
  margin-bottom: 24px;
  font-size: 1.75rem;
}

.form-control {
  display: flex;
  flex-direction: column;
  background: #ffc0cb;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.form-control input[type="text"],
.form-control input[type="email"],
.form-control input[type="file"],
.form-control textarea {
  width: 70%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin: 10px 0px;
  box-sizing: border-box;
  outline: none;
}

.form-control input[type="file"] {
  height: auto;
}


.form-control button {
  width: 70%;
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ff1493;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.form-control textarea {
  min-height: 6rem;
}


.form-control button:hover {
  background-color: #ff0066;
}

.attachments {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1045px) {
  .form-control input[type="text"],
  .form-control input[type="email"],
  .form-control input[type="file"], 
  .form-control textarea,
  .form-control button {
    width: 100%;
  }
}