.Classic-Product-Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2px;
    justify-content: space-around;
    margin-bottom: 10vh;
}

.title {
  text-align: center;
  margin-bottom: 3vh;
  color: #ff1493;
}

.images-container {
  display: flex;
  justify-content: center;
}

.images {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  align-items: center;
}

.preview-images {
  display: flex;
  flex-direction: row;
}

.preview-img {
  width: 80px;
  height: 80px;
  border: 1px solid #ff1493;
}

.classicsImg {
  width: 100%;
  height: 100%;
}

/* .classicsDetails {
    display: grid;
    gap: 0;
    grid-template-rows: repeat(2, auto)
}

.classicsDetails > *:nth-child(2){
    justify-self: start;
    align-self: center;
    background-color: #ffc0cb;
    color: #ff1493;
    padding: 12px 26px;
    border: 1px solid #ff1493;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.classicsDetails > *:nth-child(2):hover{
    transform: scale(1.1);
} */

.classicsDetails {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);   
}

.bloc-tabs {
    display: flex;
    margin-bottom: 10px;
}

.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background-color: #fff;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #d3d3d3;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.tabs:not(:last-child) {
  border-right: none;
}

.active-tabs {
  background-color: #fff;
  color: #000;
  border-color: #ff1493;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background-color: #ff1493;
  border-radius: 5px;
}

button {
  border: none;
  background: none;
  outline: none;
}

.content-tabs {
  flex-grow: 1;
}

.content {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
  line-height: 1.5;
  font-size: 16px;
}

.content-details {
  display: flex;
  flex-direction: column;
}

.content-details select {
  width: 30%;
}

.content-details h3 {
  color: #ff1493;
}

.content-button {
  display: flex;
  margin-top: 1px;
  height: 30px;
  padding: 2px 15px;
  border: 1px solid #ff1493;
  transition: all 0.3s ease-out;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.content-button:hover {
  transform: scale(1.05);
}

.content h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.content hr {
  margin: 0 auto 10px;
  width: 50px;
  height: 3px;
  background-color: #ff1493;
  border: none;
  border-radius: 5px;
}

.content p {
  margin-bottom: 10px;
}

.active-content {
  display: block;
}

@media screen and (max-width: 730px) {
  .Classic-Product-Container {
    grid-template-columns: 1fr;
  }
}