.hero-img-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -4em;
}

.intro-heading{
    text-align: center;
    margin-bottom: 2rem;
}

.intro-heading p{
    font-size: 15px
}

.hero-images {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 0.3em;
    margin: 1em 1em 0
}

.hero-img {
    width: 100%;
}

@media screen and (max-width: 500px) {
    .intro-heading p{
        font-size: 10px
    }
}