.cards-list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 20px;
    overflow-x: auto;
}

.card {
    width: 100%;
    height: 100%;
    /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px; */
    font-size: 12px;
    flex: 0 0 auto;         /* ADD */
    display: flex;          /* ADD */
    flex-direction: column; /* ADD */
}

.card--image {
    width: 100%;
    height: 70%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    cursor: pointer;
    border-radius: 10px;
    border-radius: 9px;
    margin-bottom: 9px;
    transition: transform 0.2s ease-in-out;
}

.priceSpan {
    color: #ff1493;
}

.card--image:hover {
    transform: scale(1.1);
}

.cartButton {
    background-color: #ffc0cb;
    color: #ff1493;
    padding: 8px 20px;
    border: 1px solid #ff1493;
    transition: all 0.3s ease-out;
    cursor: pointer;
    width: 40%;
}

.cartButton:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 800px){
    .cartButton {
        width: 100%;
    }
}