.invoice-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 20px;
}

.invoice-form-container > p {
    margin-bottom: 2rem;
    font-family: 'PT Sans';
}
.invoice-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.invoice-form label {
    margin-bottom: 10px;
}

.invoice-form input {
    padding: 8px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
    border: 1px solid black;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.invoice-form-submit-btn {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.invoice-form-submit-btn:hover {
    background-color: #3e8e41;
}
