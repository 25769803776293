.cart-container {
  margin: 20px;
  min-height: 60vh;
}

.cart-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cart-item {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 2px solid #ddd;
}

.cart-delete-btn {
  cursor: pointer;
}

.cart-item-image-container {
  width: 30%;
  height: auto;
  text-align: center;
}

.cart-item-image-container img {
  width: 5rem;
  height: 4rem;
  object-fit: cover;
}

.cart-item-details {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart-item-title {
  margin-bottom: 10px;
}

.cart-total-price {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.cart-proceed-btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}