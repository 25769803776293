.hero-container {
    position: relative;
    height: 97vh;
    max-height: calc(100vw / 1.5);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

@media screen and (min-width: 1000px) {
    .hero-container {
        display: grid;
        grid-template-columns: 1fr;
    }

    .hero-container video {
        /* position: absolute;
        top: 0;
        left: 0; */
        width: 100%;
        /* height: 100%;
        object-fit: fill; */
    }
    
    .hero-container img {
        display: none;
    }
}

/* .hero-container  .ChiHome {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: fill;
} */

@media screen and (max-width: 999px) {
    .hero-container {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: -5rem;
    }

    .hero-container img {
        /* position: absolute;
        top: 0;
        left: 0; */
        width: 100%;
        /* height: 100%;
        aspect-ratio: 1/1;
        object-fit: fill; */
    }
    
    .hero-container video {
        display: none;
    }
}

@media screen and (max-width: 720px) {
    .hero-container {
        margin-top: -4rem;
    }
}

@media screen and (max-width: 500px) {
    .hero-container {
        margin-top: -2em;
    }
}

@media screen and (max-width: 245px) {
    .hero-container {
        margin-top: -25px;
    }
}
