.services-hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 80%; */
  margin: 0 auto 25vh;

}

.top-image{
  margin-top: -20rem;
  width:100%;
  margin-bottom: 1.5rem;
}

.top-image img {
  width: 100%;
}

.services-intro {
  text-align: center;
  width: 90%
}

.services-intro h1{
    color:#ff1493
}

.specials-list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-top: 30px;
  width: 95%

}

.specials-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 20px;
  border: 1px solid #ff1493;
  border-radius: 5px;
  min-width: 250px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.specials-list:hover {
    transform: scale(1.05);
}

.specials-list-image {
    width: 100%;
    border-radius: 10px;
}

.specials-list h1 {
  color:#ff1493;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}

.specials-list p {
  color:black;
  font-weight: 100;
  font-size: 1.2rem;
}

/* .specials-list span {
  font-style: italic;
} */

@media screen and (max-width: 949px) {
  .top-image{
    margin-top: -15rem;
  }

  .specials-list-container {
    grid-template-columns: 1fr;
  }

  .specials-list h1 {
    text-align: center;
  }
}

@media screen and (max-width: 850px) {
  .top-image{
    margin-top: -12rem;
  }
    .services-intro{
        width: 90%;
    }
}

@media screen and (max-width: 660px) {
  .top-image{
    margin-top: -10rem;
  }
    .services-intro{
        text-align: center;
    }
}

@media screen and (max-width: 550px) {
  .top-image{
    margin-top: -8rem;
  }
}

@media screen and (max-width: 490px) {
  .top-image{
    margin-top: -6rem;
  }
}   

@media screen and (max-width: 320px) {
  .top-image{
    margin-top: -4rem;
  }
}   