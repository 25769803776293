@font-face {
    font-family: 'MoonTime Regular';
    src: url("../fonts/FontsFree-Net-MoonTime-Regular-1.ttf") format('truetype');
}

@font-face {
    font-family: 'Apricot';
    src: url("../fonts/Apricot Regular.ttf") format('truetype');
}

.footer-container {
    background: #ffc0cb ;
    padding: 4rem 0 4rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    margin-top: 2rem
}

.footer-links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.footer-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 100%;
}

#cursive-p {
    font-size: 2em;
    font-family: 'MoonTime Regular';
    text-align: center;
}

#cursive-che {
    font-size: 1em;
    color: #ff1493;
    font-family: 'Apricot';
    text-align: center;
}

.credits {
    text-align: center;
}

#legal {
    width: 100%;
    margin-top: 5rem;
    text-align: center;
}

.footer-subscription {
    align-items: center;
    text-align: center;
    color: #ff1493;
    font-weight: bold;
}

#footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 1.75rem;
}

/* Social Icons */
.social-icon-link {
    color: #ff1493;
    font-size: 24px;
    transition: all 0.3s ease-out;
}

.social-icon-link:hover {
    transform: scale(1.05);
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

@media screen and (max-width: 820px) {
    .footer-container {
        display: grid;
        grid-template-columns: .8fr;
        justify-content: center;
        align-items: center;
        gap: 5em
    }

    .footer-information {
        margin: 0 auto;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}